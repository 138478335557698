<template>
    <!-- CONTENT ================================================== -->
    <div>

<!-- breadcrumb --> 
<div class="bg-light">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <ol class="breadcrumb mb-0">
          <li style="cursor:no-drop" class="breadcrumb-item"><a > <i class="fas fa-building"></i> </a></li>
          <li style="cursor:no-drop" class="breadcrumb-item"> <i  class="fas fa-chevron-right"></i> <a >{{ data.title }}</a></li>
          <li style="cursor:no-drop" class="breadcrumb-item active"> <i  class="fas fa-chevron-right"></i> <a >Portofolio</a></li>
        </ol>
      </div>
    </div>
  </div>
</div>
<!--=================================
breadcrumb -->

  <section class="space-pb mt-4">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-8">
          <div class="section-title text-center">
            <h2> {{ data.title }}</h2>
            <p>{{ data.description }}</p>
          </div>
        </div>
      </div>
      <div v-if="fetching" class="mt-4" id="rounded">
        <div class="loading">
          <div class="loading">
            <div class="loading">
              <div class="loading">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="data.detail.length > 0 && !fetching" class="row">
          <div class="col-md-3 col-6" v-for="(item, index) in data.detail" :key="index">
            <div class="property-item">
              <div v-for="(row, ind) in item.foto" :key="ind" v-bind:id="`list-${index}-${ind}`" :style="`display:${ind === 0 ? 'block' : 'none'}`" class=" property-image bg-overlay-gradient-04">
                <img class="img-fluid" :src="getImage(`assets/produk/${data.code}/${row.image}`)" alt="">
                <div class="property-agent">
                  <div class="property-agent-image">
                    <a target="_blank" :href="`https://wa.me/6285740177785?text=Halo+Hasina...+Saya+ingin+belanja+${item.name}+(+${item.code}+)`">
                    <!-- <a target="_blank" :href="`https://wa.me/6285740177785?text=Halo+Hasina...+Saya+ingin+belanja+${item.name}+(+${item.code}+)+%0A%0A+ini linknya+%0A%0A+https://hasina.id/#/portofolio/detail/+${item.code})`"> -->
                    <img class="img-fluid" :src="getImage('assets/icon/wa-1.png')" alt="">
                    </a>
                  </div>
                  <div class="property-agent-info">
                    <a class="property-agent-name" href="#">Pesan Sekarang</a>
                  </div>
                </div>
                <div class="property-agent-popup">
                  <!-- <a href="#"><i class="fas fa-camera"></i> {{ item.count }}</a> -->
                  <a disabled style="background-color:grey" v-if="ind === 0 && item.count > 1" class="mr-1"><i class="fas fa-chevron-left"></i></a>
                  <a @click="prevImg(index, ind)" v-if="ind !== 0 && item.count > 1" class="mr-1"><i class="fas fa-chevron-left"></i></a>
                  <a @click="nextImg(index, ind)" v-if="ind < (item.count-1) && item.count > 1"><i class="fas fa-chevron-right"></i></a>
                  <a disabled style="background-color:grey" v-if="ind === (item.count-1) && item.count > 1"><i class="fas fa-chevron-right"></i></a>
                </div>
              </div>
              <div class="property-details">
                <div class="property-details-inner">
                  <h5 class="property-title"><router-link :to="`/portofolio/detail/${item.code}`" >{{ item.name }}</router-link></h5>
                  <span class="property-address"><i class="fas fa-ticket-alt fa-xs"></i>{{ item.code }}</span>
                </div>
              </div>
            </div>
          </div>
      </div>
      <div  v-if="data.detail.length === 0 && !fetching" style="background-color:#f8f9fa" class="p-4 text-center empty-state">
          <i style="font-size:50px" class="mb-3 fas fa-folder-open"></i>
          <h5>No Data Available</h5>
          <p>Sorry, there is no data to display at the moment.</p>
      </div>
    </div>
  </section>

  

    </div>
</template>

<script>
    // import axios from "axios";

    export default {
        name: 'Portofolio',
        metaInfo: {
            title: 'Hasina',
            titleTemplate: '%s - Portofolio',
            meta: [
              {
                name: 'keywords',
                content: 'Hasina batik, Hasina konveksi, Hasina clothing, Hasina Merchandise, Hasina id, Warna seragam dinas resmi bps'
              }
            ]
        },
        mounted() {
            this.loadProduct();
        },
        data() {
            return {
                code: this.$route.params.id ? this.$route.params.id : '',
                fetching: false,
                data:[]
            }
        },
        methods: {
            getImage(url){
                if (url.match(this.baseUrl)) {
                    return url;
                } else {
                    return this.baseUrl + url;
                }
            },
            loadProduct() {
                this.fetching = true;
                this.$http.get(this.$apiconfig + "protofolio", {
                    params: {
                        code: this.code,
                    }
                })
                    .then((response) => {
                        this.data = response.data.data;
                        this.fetching = false;
                    })
                    .catch((error) => {
                        console.log(error.response.data);
                    });
            },
            getProduct(code) {
                this.fetching = true;
                this.$http.get(this.$apiconfig + "protofolio", {
                    params: {
                        code: code,
                    }
                })
                    .then((response) => {
                        this.data = response.data.data;
                        this.fetching = false;
                    })
                    .catch((error) => {
                        console.log(error.response.data);
                    });
            },
            prevImg(index, ind) {
              const fixInd = ind-1;
              document.querySelector("#list-"+index+"-"+ind).style.display = "none";
              document.querySelector("#list-"+index+"-"+fixInd).style.display = "block";
            },
            nextImg(index, ind) {
              const fixInd = ind+1;
              document.querySelector("#list-"+index+"-"+ind).style.display = "none";
              document.querySelector("#list-"+index+"-"+fixInd).style.display = "block";
            }
        },
        beforeRouteUpdate(to, from, next) {
          // this.fetchData(); // Panggil method fetchData saat rute berubah
          const code = to.params.id;
          this.getProduct(code);
          next();
        }
    }
</script>